/* PARTIAL: SITE OVERLAY
-------------------------------------------- */

.ptf-site-overlay {
	position: fixed;
	top: 0;
	left: 0;
	z-index: z('site-overlay');

	display: none;

	width: 100%;
	height: 100%;

	background-color: var(--ptf-color-18);

	cursor: pointer;
}
