/* PARTIAL: FILLED LINK
-------------------------------------------- */

.ptf-filled-link {
	display: inline-flex;

	color: inherit;

	background-image: linear-gradient(to right, var(--ptf-accent-1), var(--ptf-accent-1) 50%, currentColor 50%);
	background-position: 100% 0;
	-webkit-background-clip: text;
	background-clip: text;
	background-size: 200% 200%;

	transition: background-position calc(var(--ptf-transition-duration) * 2) var(--ptf-transition-easing);

	-webkit-text-fill-color: transparent;

	&:hover {
		color: inherit;

		background-position: 0 0;
	}

}
