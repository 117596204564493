/* WIDGET: VIDEO BUTTON
-------------------------------------------- */

.ptf-video-button {
  --ptf-title-color: var(--ptf-color-black);
  cursor: pointer;
}

.ptf-video-button {
  display: inline-flex;
  align-items: center;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    width: px2rem(64px);
    height: px2rem(64px);
    margin-right: px2rem(16px);
    padding-left: 0.2em;

    color: var(--ptf-title-color);
    font-size: px2rem(24px);
    line-height: 1;

    border-radius: 50%;
    background-color: transparent;
    box-shadow: inset 0 0 0 1px var(--ptf-title-color);

    &:hover {
      color: var(--ptf-color-white);

      background-color: var(--ptf-accent-1);
      box-shadow: inset 0 0 0 1px var(--ptf-accent-1);
    }
  }

  &__title {
    text-transform: uppercase;
    color: var(--ptf-title-color);
    font-size: px2rem(14px);
    font-weight: 700;
  }
}
a,
button,
.ptf-checkbox {
  cursor: pointer !important;
}
