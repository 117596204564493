.landing-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  padding: 20px;
  font-family: Arial, sans-serif;
  text-align: center;
  background: #171717; /* Clean, minimal background */
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
  color: #fff;
}

.logo:hover {
  color: #c60000;
}

.social-links a {
  margin-left: 15px;
  text-decoration: none;
  color: #fff;
}

.social-links a:hover {
  color: #c60000;
}

.tagline {
  color: #fff;
  font-size: 6.2rem;
  font-weight: 300;
}

.tagline2 {
  color: #fff;
  font-size: 1.2rem;
  font-weight: 300;
}

.contact {
  font-size: 1.2rem;
  font-weight: 300;
  color: #777;
}
.contact a {
  text-decoration: none;
  color: #ffffff;
}
.contact a:hover {
  text-decoration: underline;
  color: #c60000;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
